<template>
    <div>
        <vs-popup :title="$t('MESSAGES.POPUP.TITLE')" :active.sync="popupActiv">
            <vs-list>
                <vs-list-item :title="From" :subtitle="DateTime">
                    <template slot="avatar">
                        <vs-avatar vs-text="Vuesax"/>
                    </template>
                    <vs-button :disabled="!IsDocumentAvailable" :color="Color" type="border" icon="attach_file" @click="DownloadFile()"></vs-button>
                </vs-list-item>
                <vs-list-item class="message_title" :title="Title" :subtitle="Text"></vs-list-item>
            </vs-list>
            <vs-button class="mt-3"  :color="Color" type="border" icon="delete" @click="DeleteMessage()">{{$t('COM.DELETE')}}</vs-button>
        </vs-popup>       
    </div>
</template>
<script>

import codeHelper from '../../../helper/staticCodeHelper';
import downloadMethods from '../../../helper/staticDownloadFuncHelper';

export default {
    name: "MessagePopUp",
    components:{
        
    },
    props:['Title','Text','From','Color','DateTime','Guid','DocumentIds'],
    data: function (){
        return {
            popupActiv:false
        }
    },
    methods:{
    ...codeHelper,
    ...downloadMethods,
        DeleteMessage(){
            this.popupActiv = false;
            this.$store.dispatch('messages/markAsDeleted', this.Guid ); 
        },
        ShowPopUp(){
            this.popupActiv = true;
        },
        DownloadFile(){
            if(this.IsDocumentAvailable)
            {
                var ids = this.DocumentIds.split("#");

                for(var i=0; i < ids.length; i++)
                {
                    this.$store.dispatch('document/downloadDocument', Number(ids[i]))
                    .then(response => {
                        this.DownloadWithFileSaver(response);
                    });
                }
                
            }
        }
    },  
    watch:{
        popupActiv(value){
            if(value==false)
            {
                this.$store.dispatch('messages/markAsRead', this.Guid ); 
            }
        }
    },
    computed:{
        IsDocumentAvailable(){
            var retVal = false;
            if(this.IsNotNull(this.DocumentIds))
            {
                retVal = this.DocumentIds.length > 0;
            }
            return retVal;
        }
    }
};
</script>
<style scoped>
.message_title{
  font-size: 1.5em;
}
</style>
