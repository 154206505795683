export default {
    IsDefined(val){
        return typeof(val) != 'undefined';        
    },
    IsNotNull(val){
        if(this.IsDefined(val)){
            return val != null;
        }
    },
    IsEmpty(obj){
        return Object.keys(obj).length === 0;
    },
    GetDateTimeNow(){
        var currentdate = new Date();
        return currentdate.toISOString();
    },
    Round(x){
        return Number(Number.parseFloat(x).toFixed(2));    
    },
    GetPaymentDiff()
    {
        return 0.05;
    },
    LimitTextTo(txt,max)
    {
        var retVal = "";
        if(this.IsNotNull(txt))
        {
            if(txt.length > max)
            {
                retVal = txt.substring(0, Number(max)) + '...';
            }
            else
            {
                retVal = txt;
            }
        }
        return retVal;
    },
    IsTextEmpty(text){
        var ret=true;
        if(this.IsNotNull(text))
        {
            if(text.length > 0)
            {
                ret = false;
            }
        }
        return ret;
    },
    GetLinkedTextsFromArray(lines, headers){
        var textline = "";
        var headerline = "";

        var texts = [];
        var header_texts = [];

        for(var j = 0; j < lines.length; j++)
        {
          if(lines[j] != "")
          {
            texts.push(lines[j]);
            if(headers.length > j)
            {
                header_texts.push(headers[j]);
            }
          }
        }

        if(texts.length > 1)
        {
          for(var i = 0; i < texts.length; i++)
          {
            textline += texts[i];
            if(i != (texts.length - 1))
            {
                textline  += ' | ';
            }
            
          }
        }
        else
        {
            // leer oder nur 1 Eintrag
          texts.forEach(element => {
            textline += element;
          });
        }

        if(header_texts.length > 1)
        {
          for(i = 0; i < header_texts.length; i++)
          {
            headerline += header_texts[i];
            if(i != (header_texts.length - 1))
            {
                headerline  += ' | ';
            }
            
          }
        }
        else
        {
            // leer oder nur 1 Eintrag
            header_texts.forEach(element => {
                headerline += element;
          });
        }

        return {line: textline, header: headerline};
    }
    
}